import React from "react";
import "./HsrBar.scss"
const HsrBar = () => {
  return <div>
      <div className="hsrContainer">
          <p>Card Schaped USB Drive & printing</p> |
          <p>CD Cover's</p> |
          <p>CD Sticker's</p> |
          <p>CDs and DVD's</p> |
          <p>Visiting Cards</p> |
          <p>Wedding Cards</p> |
          <p>Inviation's</p> |
          <p>Brochure's</p> |
          <p>Notices</p> |
          <p>Letter Pad</p> |
          <p>Paper Cover's</p> |
          <p>Stickers</p> |
          <p>Wall Posters</p> |
          <p>Books & Catalog</p> |
          <p>Form & Sun back</p> |
          <p>Hand Fan</p> |
          <p>Corporate Gift items & Printings</p>
      </div>
  </div>;
};

export default HsrBar;
