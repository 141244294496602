import React, { useEffect } from 'react';
import cd from '../../../images/Features/cd.png'
import bg1 from '../../../images/design/bg1.png'
import './Demo.scss'
import { ScrollTrigger } from 'gsap/all';

import {Features} from '../../../Data/Data'
import gsap from 'gsap/gsap-core';
import HsrBar from '../../../Components/HsrBar/HsrBar';
const Demo = () => {
    useEffect(()=>{
        gsap.registerPlugin(ScrollTrigger)
        var mods = document.querySelectorAll('.fitem .img');
        mods.forEach((mod)=>{
            gsap.from(mod,0.5,{scrollTrigger:{
                trigger:mod,
                scrub:true,
                start:"center bottom",
                end:"center 60%",
                // markers:true
                
            },opacity:0,scale:0.5,rotate:"35deg"})
        })
        // Text normal
        var mods = document.querySelectorAll('.norev .content');
        mods.forEach((mod)=>{
            gsap.from(mod,0.5,{scrollTrigger:{
                trigger:mod,
                scrub:true,
                start:"center bottom",
                end:"center 60%",
                // markers:true
                
            },opacity:0,translateX:"300px"})
        })
        // Text reverse
        var mods = document.querySelectorAll('.reverse .content');
        mods.forEach((mod)=>{
            gsap.from(mod,0.5,{scrollTrigger:{
                trigger:mod,
                scrub:true,
                start:"center bottom",
                end:"center 60%",
                // markers:true
                
            },opacity:0,translateX:"-300px"})
        })

    },[])
    console.log(Features)
    return ( 
        <div id="Demo">
            <h2 className="text-info text-center mb-5">Our Services</h2>
            <div className="container">
            <HsrBar/>
            </div>

            <div className="featureList containers">
                {
                    Features.map((feature,i)=>{
                        var c = i%2==0?"fitem norev":"fitem reverse"
                        return(<div key={i} className={c} >
                            <div className="img"><img src={feature['img']} alt=""/></div>
                            <div className="content">
                            <div className="bg1">
                                    <img className="" src={bg1} alt=""/>
                                    </div>
                                <div className="info"><h2>{feature['title']}</h2>
                                <p>{feature['desc']}</p></div>
                            </div>
                        </div>)
                    })
                }
                {/* <div className="fitem reverse">
                    <div className="img"><img src={cd} alt=""/></div>
                    <div className="content">
                        <div className="bg1">
                            <img className="" src={bg1} alt=""/>
                            </div>
                        <div className="info"><h2>Lorem ipsum dolor sit amet.</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non consequatur atque eligendi dolores libero quasi aut quo illo laudantium perspiciatis!</p></div>
                    </div>
                </div> */}
            </div>
        </div>
     );
}
 
export default Demo;