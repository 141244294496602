import React from 'react';
import dp from './../../images/dp.jpg'
import './ContactUs.scss'
const ContactUs = () => {
    return ( 
        <div>
            <div className="row p-0 m-0">
                <div className="col-sm-12 col-md-6 col-lg-4 ">
                    <div className="detials" id="detials">
                        <img src={dp} alt=""/>
                        <p className="name text-primary">S.M.J. Kingston</p>
                        <p className="desc">Founder, SpaceDigitalMedia</p>
                        <div className="address">
                            <div className="icon"><i className="fas fa-map-marker-alt"></i></div>
                            {/* TODO: remove br */}
                            <p className="addr container">No. 534/6, M.S. Road, Near Post office, Vetturnimadam, Nagercoil - 629003</p>
                        </div>
                        <div className="email mb-3">
                            <div className="icon"><i class="fas fa-envelope"></i></div>
                            {/* <p className="mail">spacedigitalmedia@gmail.com</p> */}
                            <a href="mailto:spacedigitalmedia@gmail.com" className="mail">spacedigitalmedia@gmail.com</a>
                        </div>
                        <div className="bottom">
                            <div className="phone">
                                <div className="icon"><i class="fas fa-phone"></i></div>
                                {/* <p className="ph">04652 223379</p> */}
                                {/* <p className="ph">+91 90037 35379</p> */}
                                <a href="tel:+91 90037 35379" className="ph">+91 90037 35379</a>
                            </div>
                            <div className="phone">
                                <div className="icon"><i class="fas fa-phone"></i></div>
                                {/* <p className="ph">04652 223379</p> */}
                                <a href="tel:+91 04652 223379" className="ph">04652 223379</a>
                                {/* <p className="ph">+91 90037 35379</p> */}
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-8 frm" >
                    <form id="contactForm" className="card p-4 m-2" name="contact" action="/index.html" method="POST" data-netlify="true" onSubmit={(e)=>{
                        alert(0)
                    }}>
                        <h1 className="text-center text-info mb-3">Get in touch</h1>
                        <h3 className="card-heading"></h3>
                        <div className="form-group">
                            <label htmlFor="FormName">Name </label>
                            <input className="form-control" type="text" name="name" id="FormName"/>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Email address</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                            <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                        </div>
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1">Message / Feedback / Queries</label>
                            <textarea class="form-control" name="message" id="exampleFormControlTextarea1"></textarea>
                        </div>
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
        </div>
     );
}
 
export default ContactUs;