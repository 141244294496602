import React from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import Demo from './Demo/Demo';
import Hero from './Hero/Hero';


const HomePage = () => {
    return ( 
        <div id="HomePage">
            <Hero/>
            <Demo/>
        </div>
    );
}
 
export default HomePage;