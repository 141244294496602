import React from 'react';
import logo from '../../images/logo.jpg'
import {Link} from "react-router-dom";

const NavBar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light px-lg-5  justify-content-between">
            <img id="navLogo" src={logo} alt=""/><a className="navbar-brand text-primary h3" href="#">SpaceDigitalMedia</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end " id="navbarNav">
                <ul className="navbar-nav font-weight-normal">
                <li className="nav-item active">
                    <Link to="/"  >
                    <a className="nav-link text-primary ">Home <span className="sr-only">(current)</span></a>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link  to="/contact"><a className="nav-link text-primary" >Contact us</a></Link>
                </li>
                <li className="nav-item">
                    <Link  to="/works"><a className="nav-link text-primary" >Our Works</a></Link>
                </li>
                </ul>   
            </div>
        </nav>
    );
}
 
export default NavBar;