import React from 'react';
import HsrBar from '../HsrBar/HsrBar';

const Footer = () => {
    return ( 
        <footer className="bg-dark text-white pl-lg-5 pl-sm-0 py-4">
            {/* <div className="p-2 mb-4">
            <HsrBar/>
            </div> */}
            <div className="row container-fluid  ">
                <p className="col-lg-6 col-sm-12">© spacedigitalmedia.in</p>
                <p className="col-lg-6 col-sm-12 text-sm-left text-lg-right  ">Designed and developed by <span className="bg-warning py-1 px-2 rounded-lg"><a href="https://oswin1998.cf/" className="text-primary" target="_blank" rel="noopener noreferrer">Oswin Jerome</a></span></p>
            </div>
        </footer>
     );
}
 
export default Footer;