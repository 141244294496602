import React, { useEffect } from 'react';
import './Hero.scss'
import d1 from '../../../images/design/d1-bak.png'
import d2 from '../../../images/design/d2.png'
import logo from '../../../images/logo.png'
import Typewriter from 'typewriter-effect/dist/core';
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import HsrBar from '../../../Components/HsrBar/HsrBar';
import yt from '../../../images/design/yt.png'
import fb from '../../../images/design/fb.png'
const Hero = () => {
    useEffect(()=>{
        

        var timeline = new gsap.timeline({onComplete:()=>console.log("Animation "),});
        timeline.from("#d1",{left:"-400px",ease:"expo.in",duration:1},)
        timeline.from("#d2",{bottom:"-600px",ease:"expo.in",duration:1},"-=0.8")
        timeline.from("#logo",{opacity:0,duration:0.5})
        timeline.from("#catch-text",{opacity:0,duration:0.5},"-=0.2")
        timeline.from("#runner",{opacity:0,duration:0.5},"-=0.2")
        timeline.from(".buttonBar",{opacity:0,duration:0.5},"-=0.2")
        timeline.from("#yt",{opacity:0,translateX:"160px",duration:1.5},"-=.2")
        timeline.from("#fb",{opacity:0,translateX:"160px",duration:1.5},"-=.8")
        
        new Typewriter('#type',{
            strings:["Visiting Cards","Invitations","Brochures","Notices","Letter Pad","Bill Book","CD Covers and Stickers","Digital Video","Post production of CD's and DVD's","Cassette to CD/DVD"],
            autoStart:true,
            loop:true,

        });
    },[])
    return ( <div id="Hero">
    <img id="logo" src={logo} alt=""/>
   {/* <h1 id="catch-text" className="text-primarydark">Space Digital Media</h1> */}
   <h1 id="catch-text" className="text-primarydark">A Gateway to Digital World</h1>
    {/* <h4 className="text-secondary">A Gateway to Digital World</h4> */}
    <h3 id="runner">We make <span className="text-primary" id="type"></span></h3>
    
   <div className="buttonBar">
       <a href="/contact#contactForm" className="button bttn">Get a Quote</a>
       <a href="/contact#contactForm" className="button bttn-outline">Contact Us</a>
   </div>
   
   <img src={d1} alt="" id="d1"/>
   <img src={d2} alt="" id="d2"/>
   <a href="https://www.youtube.com/channel/UCpGLVszNbKnyVQGWbQgA0_A" target="_blank" id="yt">
        <img src={yt}></img>
      </a>
      <a href="https://www.facebook.com/spacedigitalmedia.ngl" target="_blank" id="fb">
        <img src={fb}></img>
      </a>
</div> );
}
 
export default Hero;