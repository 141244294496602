import React from 'react';
import './Works.scss'
import dp from '../../images/logo.png'
import {Gallary} from '../../Data/Data'
import HsrBar from '../../Components/HsrBar/HsrBar';
import { Link } from 'react-router-dom';
const Works = () => {
    return ( 
        <div id="Works">
            <div className="container">
                <h2 className="heading text-center mt-5">Checkout some of our recent works</h2>
                <div id="gallary" className="row mb-5">
                    {
                        Gallary.map((img,i)=>{
                            return(
                                <img src={img['image']} alt="" className=" col-6 col-sm-6 col-md-4 col-lg-3"/>
                            );
                        })
                    }
                </div>
                <HsrBar />
                <div className="buttonBar2">
                    <a href="/contact#contactForm" className="button bttn">Get a Quote</a>
                    <a href="/contact#contactForm" className="button bttn-outline">Contact Us</a>
                </div>
                
            </div>
        </div>
     );
}
 
export default Works;